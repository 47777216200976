/* @import url('http://fonts.cdnfonts.com/css/product-sans'); */

* {
  font-family: 'Product Sans', sans-serif;
}

body {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

/* Layout <=============== */

.layout-bg {
  height: 100vh;
  position: relative;
  /* background-color: #EEF0FA; */
}

/* authlayout <==================== */

.authlayout {
  height: 100vh;
  background-color: #EEF0FA;
  width: 100vw;
  overflow: hidden;
  /* overflow-y: auto; */
}


/* Login <=================== */

.login-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 60px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275), 0px 0px 10.0172px rgba(0, 0, 0, 0.035), 0px 0px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 20px;
}

.login-title {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  text-align: center;
  color: #000000;
  margin-bottom: 18px;
}

.login-form {
  width: 450px;
  text-align: start;
}

.login-form label {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: block;
  line-height: 25px;
  color: #000000;
}

.login-form input {
  margin-top: 5px;
  width: 100%;
  height: 49px;
  padding: 0 15px;
  background: #f2f2f2;
  border-radius: 8px;
  border: none;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 20px;
}

.login-form input::placeholder {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(56, 56, 116, 0.35);
}

.remember-me {
  text-align: start;
  display: flex;
  align-items: center;
}

.remember-me label {
  margin-left: 10px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.remember-me input {
  height: 16px;
  width: 16px;
}

.remember-me .form-check-input[type='checkbox'] {
  border-radius: 4px !important;
}

.remember-me .form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  margin-top: 4px;
}

.login-main button {
  margin-top: 30px;
  width: 200px;
  height: 49px;
  background: #0d6efd;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
}

.login-main .disabled {
  background: #F0F0F0 !important;
}

.show-password {
  width: 30px;
  position: absolute;
  top: 44px;
  right: 15px;
  cursor: pointer;
}

/* Header <================ */

.header-main {
  position: relative;
  width: 100%;
  padding-right: 32px;
  background: #FFFFFF;
  box-shadow: 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main input {
  background: transparent;
  height: 40px;
  width: 380px;
  border: 1px solid #E9ECEF;
  border-radius: 4px;
  padding: 0px 20px;
}

.profile {
  display: flex;
  gap: 20px;
  align-items: center;
}

.profile img {
  height: 20px;
  cursor: pointer;
}

.profile h3 {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px;
}

.profile h5 {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6F73CD;
  margin-bottom: 0px;
}

.profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.profile-pic img {
  height: 100%;
  width: 100%;
}

.multi_delete_icon {
  height: 28px;
  cursor: pointer;
}

/* Sidebar<============== */

.sidebar-open {
  width: 257px;
  transition: all ease-in 0.3s;
  /* padding: 0px 12px; */
  border-top: 1px solid #f2f2f2;
  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
}

.sidebar-open-2 {
  width: 257px;
  position: absolute;
  z-index: 3;
  /* padding: 0px 12px; */
  border-top: 1px solid #f2f2f2;
  background: #FFFFFF;
  /* transition: all ease-in-out 0.8s; */
  box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
}

.sidebar-close {
  width: 75px;
  transition: all ease-in 0.3s;
  /* padding: 0px 12px; */
  border-top: 1px solid #f2f2f2;
  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
}

.sidebar-close-2 {
  width: 0px;
  /* transition: all ease-in 0.3s; */
}

.sidebar-logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar-manu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 91.5vh;
  padding: 0px 12px;
}

.manu-item .manu-item-btn {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  gap: 15px;
  height: 44px;
  align-items: center;
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #131313 !important;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: none !important;
  margin: 10px 0px;
  text-align: left;
}

.manu-item .active {
  background-color: #7462ff !important;
  color: #FFFFFF !important;
  box-shadow: 0px 2px 4px rgba(138, 146, 166, 0.3) !important;
}

.sidebar-arrow {
  margin-left: auto !important;
}

.manu-item .manu-item-btn-list {
  width: 100%;
  height: 44px;
  margin: 3px 10px;
  background-color: transparent !important;
  border: none !important;
  color: #8484A0;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  box-shadow: none !important;
  padding-left: 45px;
}

.manu-item .manu-item-btn-list:hover {
  color: #0d6efd;
  font-weight: 400;
  font-size: 16px;
}

.manu-item .manu-item-btn-list:hover::after {
  background-color: #0d6efd;
}

.manu-item .manu-item-btn-list::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8484A0;
  top: 50%;
  left: 30px;
  transform: translate(-50%, -50%);
}

.manu-item .manu-item-btn-list:focus {
  color: #0d6efd !important;
}

.manu-item .manu-item-btn-list:focus::after {
  background-color: #0d6efd;
}

.rotate-item-img {
  transform: rotate(90deg);
}

.logout-btn {
  width: 100%;
  display: flex !important;
  /* justify-content: space-between; */
  gap: 15px !important;
  height: 44px;
  align-items: center;
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #131313 !important;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: none !important;
  margin-top: auto !important;
  text-align: left !important;
}

.link-modal {
  padding: 10px;
  position: absolute;
  left: 65px;
  width: 250px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12.87px rgba(108, 73, 172, 0.1117), 0px 0px 10.0172px rgba(108, 73, 172, 0.035), 0px 0px 5.32008px rgba(108, 73, 172, 0.0282725), 1px 0px 2.21381px rgba(108, 73, 172, 0.0597);
  border-radius: 0px 10px 10px 10px;
}

/* Dashboard<================= */

.dashboard-main {
  padding: 25px;
  height: 100%;
  overflow: auto;
}

.dashbord-card-main {
  height: 100%;
  padding: 15px;
  background: #FFFFFF;
  /* box-shadow: 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802); */
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275), 0px 0px 10.0172px rgba(0, 0, 0, 0.035), 0px 0px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 6px;
}

.dashbord-card-main .title {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0px;
}

.dashbord-card-main .header {
  cursor: pointer;
}

.dashboard_extra_logo_count {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #7462ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: -5px;
}

.dashboard_extra_logo_count span {
  color: #FFFFFF;
  font-size: 12px;
}

.app-count {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 25px;
  color: #000000;
}

.card-footer {
  border-top: 1.5px solid #A1A3D4 !important;
  padding-top: 15px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-footer h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 175%;
  color: #000000;
}

.card-footer div {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 175%;
  color: #131313;
}

/* .card-footer h5 span{
  padding: 8px;
  border-radius: 50%;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  margin-left: 10px;
} */

.card-footer .inDevelopment {
  background: #6F73CD;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
}

/* .card-footer .inReview {
  background: #e3c107;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
} */

.card-footer .active {
  background-color: #1AA053;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
}

.card-footer .live {
  background-color: #1AA053;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
}

.card-footer .removed {
  background-color: #F16A1B;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
}

.card-footer .suspended {
  background-color: #F21F1F;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #FFFFFF;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
}


/* Android<=================== */

.android-main {
  padding: 25px;
  /* height: 92vh;
  overflow: auto; */
  position: relative;
  height: 100%;
  overflow: auto;
}

.android-main-card {
  background: #FFFFFF;
  /* box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802); */
  /* box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275), 0px 0px 10.0172px rgba(0, 0, 0, 0.035), 0px 0px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0px 2.21381px rgba(0, 0, 0, 0.0196802); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 6px;
  padding: 25px;
  border: none !important;
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* .android-main-card .title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 20px;
  color: #131313;
} */

.form-label {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0px !important;
}

.form-input {
  height: 40px;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  color: #000000 !important;
}

.resume-file {
  outline: none;
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 3px !important;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 35px; */
}

.resume-file p {
  margin-bottom: 0px !important;
}

.android-main-card-footer {
  margin-top: auto;
  display: flex;
  justify-content: end;
  gap: 25px;
}

.android-main-card-footer .cancel-btn {
  background: #f2f2f2 !important;
  border-radius: 4px !important;
  border: none !important;
  color: #000000 !important;
  width: 178px;
  height: 55px;
  box-shadow: none !important;
}


.android-main-card-footer .save-btn {
  width: 178px;
  height: 55px;
  border: none;
  background: #0d6efd !important;
  box-shadow: 0px 2px 4px rgba(138, 146, 166, 0.3) !important;
  border-radius: 4px !important;
}

/* LiveApps<======================= */

/* .android-main-card table thead {
  background: #EEF0FA;
  height: 50px;
}

.android-main-card table thead tr th {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #383874;
  min-width: 150px;
  max-width: 200px;
}

.android-main-card table thead tr th:first-child {
  padding-left: 30px !important;
}

.android-main-card table tbody {
  height: 50px;
}

.android-main-card table tbody tr td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #383874;
  min-width: 150px;
  max-width: 200px;
}

.android-main-card table tbody tr td:first-child {
  padding-left: 30px !important;
} */

.MuiTableRow-head {
  background-color: #DBDFFD;
}

.MuiTableCell-root {
  border: none !important;
  padding: 8px 16px !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.login-popup .modal-dialog {
  justify-content: center;
}

/* .login-popup .modal-content {
  background-image: url(./assets/img/Rectangle801.png) !important;
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
  height: 35vh;
  border: none !important;
  border-radius: 25px;
  box-shadow: none !important;
  width: 580px;
} */

.login-popup .modal-content {
  border-radius: 16px;
}

.login-popup .modal-body {
  text-align: center;
  /* padding-top: 85px; */
}

/* .logout-popup-icon {
  background: #FFFFFF;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  margin: 0 auto;
  position: absolute;
  top: -63px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
} */

.logout-popup-icon {
  background: #FFFFFF;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin: 0px auto 20px;
  /* position: absolute; */
  /* top: -32px; */
  /* left: 50%; */
  /* transform: translate(-50%); */
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 12.87px rgba(108, 73, 172, 0.1117), 0px 0px 10.0172px rgba(108, 73, 172, 0.035), 0px 0px 5.32008px rgba(108, 73, 172, 0.0282725), 1px 0px 2.21381px rgba(108, 73, 172, 0.0597);
  justify-content: center;
}

.login-popup .modal-body h4 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 25px;
  text-align: center;
  color: #131313;
}

.login-popup .modal-body h2 {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 394px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  color: #131313;
}

.login-popup .modal-body .cancel-btn {
  background: #f2f2f2;
  border-radius: 4px;
  border: none;
  padding: 10px 35px;
  color: #000000;
}

.login-popup .modal-body .save-btn {
  background: #0d6efd;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  padding: 10px 35px;
}

.MuiPaper-elevation {
  box-shadow: none !important;
}

/* .addapp-btn {
  background: #0d6efd !important;
  border-radius: 4px !important;
  border: none !important;
  color: #FFFFFF !important;
  padding: 10px 35px !important;
  margin-left: auto !important;
  box-shadow: none !important;
} */

/* Profile Manu<====================== */

.profile-manu {
  padding: 10px;
  position: absolute;
  right: 68px;
  top: 55px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12.87px rgba(108, 73, 172, 0.1117), 0px 0px 10.0172px rgba(108, 73, 172, 0.035), 0px 0px 5.32008px rgba(108, 73, 172, 0.0282725), 1px 0px 2.21381px rgba(108, 73, 172, 0.0597);
  border-radius: 8px 0px 8px 8px;
}

.profile-manu-links {
  display: flex;
  padding: 10px;
  gap: 15px;
  align-items: center;
  border-bottom: 1px solid #000000;
}

.profile-manu-links h6 {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #8484A0;
  margin-bottom: 0px;
}

/* scrollbar<================ */

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #000000;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}


/* Error<===================== */

.error-bg {
  background-color: #f2f2f2;
  height: 100vh;
}

.error-main {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 60px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198), 0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035), 0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
  border-radius: 20px;
}

.error-img {
  margin: 10px auto 20px;
  display: block;
  width: 380px;
}

.error-main .error-title {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin: 20px 0px;
}

.error-text {
  width: 323px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin: 20px auto;
}

.error-main .save-btn {
  width: 178px;
  height: 55px;
  border: none;
  background: #0d6efd !important;
  box-shadow: 0px 2px 4px rgba(138, 146, 166, 0.3) !important;
  border-radius: 4px !important;
}

@media (max-width:991px) {
  .header-togal img {
    position: absolute;
    z-index: 4;
    top: 4px;
    left: 10px;
    background: #FFFFFF;
    padding: 15px;
  }

  .header-togal input {
    margin-left: 75px;
  }
}

@media (max-width:768px) {
  .android-main-card-footer {
    margin-top: 30px;
  }
}

@media (max-width:574px) {

  .header-main input {
    width: auto;
  }

  .android-main {
    padding: 30px 10px;
  }

  .header-main {
    padding-right: 25px;
  }

  .android-main-card {
    padding: 25px 15px;
  }

}

@media (max-width:400px) {

  .header-togal input {
    margin-left: 60px;
  }

  .header-main input {
    width: 185px;
    height: 35px;
  }

  .header-main {
    padding-right: 20px;
  }

  .header-togal img {
    padding: 15px 10px;
  }

  .android-main {
    padding: 30px 10px;
  }

  .profile-pic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .profile-manu {
    right: 48px;
  }

}


.MuiPaper-elevation {
  margin-bottom: 0px !important;
  border-radius: 16px !important;
}

.MuiTablePagination-selectLabel {
  display: none !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}


/* New design <<<==<<<==<<<==<<<==<<<==<<<==<<<==<<<==<<<==<<<== */

.card__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ced4da;
}

.card__header .title {
  color: #131313;
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.header_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.header_button {
  background: #7462FF !important;
  border-radius: 5px !important;
  border: none !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 22px;
  line-height: 27px;
  height: 40px;
  text-align: center;
}

.header_cancel_button {
  background: #DBDFFD !important;
  border-radius: 5px !important;
  border: none !important;
  font-style: normal;
  font-weight: 500 !important;
  color: #131313 !important;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
}

.card__body {
  padding: 10px 20px !important;
}

.app_logo {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.live_status {
  /* background-color: #00ff00; */
  /* padding: 5px; */
  color: #1AA053;
  font-weight: 700;
  border-radius: 3px;
}

.development_status {
  /* background-color: #04a1e8; */
  /* padding: 5px; */
  color: #04a1e8;
  font-weight: 700;
  border-radius: 3px;
}

.review_status {
  /* background-color: #ffd800; */
  /* padding: 5px; */
  font-weight: 700;
  border-radius: 3px;
  color: #e3c107;
}

.removed_status {
  /* background-color: #7f7f7f;
  padding: 5px; */
  border-radius: 3px;
  color: #7f7f7f;
  font-weight: 700;
}

.suspended_status {
  /* background-color: #f62700;
  padding: 5px; */
  border-radius: 3px;
  color: #f62700;
  font-weight: 700;
}

.detail_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0px;
  border-top: 1px solid #ced4da;
  margin-top: 20px !important;
  padding: 10px 0px;
}

.view_table {
  /* border-collapse: collapse; */
  width: 100%;
}

.view_table tbody tr td {
  padding: 5px 15px;
  min-width: 50%;
  border: 1px solid #E3E3E3;
}

.view_table tbody tr td:first-child {
  background-color: #ebecf8;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0px !important;
}

.view_table tbody tr td:last-child {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
  word-wrap: break-word;
}

.notification_title {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.notification_title p {
  margin-bottom: 0px;
}

.notification_detail {
  display: flex;
  justify-content: space-between;
}

.notification_detail h2 {
  font-size: 14px;
}

.notification_detail h4 {
  font-size: 14px;
}

.user_details {
  font-size: 14px;
}

.activity_loading {
  height: 80vh;
}

.activity_card__header {
  /* gap: 20px; */
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ced4da;
  background-color: #FFFFFF;
}

.activity_card__header .title {
  color: #131313;
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}



.activity_log_main {
  background-color: #FFFFFF;
  padding: 20px;
  display: flex;
  gap: 15px;
  margin-top: 15px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}

.activity_log_main .title {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 0px;
}

.activity_log_main .user {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 0px;
}

.view_activity_modal .modal-header {
  gap: 20px;
}

.view_activity_modal .modal-header .header_detail .title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}

.view_activity_modal .modal-header .header_detail .type {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
}

.view_activity_modal_table {
  border-collapse: collapse;
  width: 100%;
}

.view_activity_modal_table thead tr th {
  padding: 5px 15px;
  min-width: 50%;
  overflow: hidden !important;
  max-width: 50%;
  border: 1px solid #E3E3E3;
}

.view_activity_modal_table tbody tr td {
  padding: 5px 15px;
  min-width: 50%;
  max-width: 50px;
  overflow: hidden !important;
  border: 1px solid #E3E3E3;
  word-wrap: break-word;
}

.card_filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.card_filter_search {
  display: flex;
  gap: 20px;
}

.card_filter_search input {
  height: 40px;
  border-radius: 3px;
  border: 1px solid #ced4da;
  padding: 10px;
}

.td_design {
  background-color: #dbdffd54;
}

.MuiList-root.MuiMenu-list {
  max-height: 500px !important;
  overflow: auto !important;
}

.td_fildName {
  color: #000000;
  font-weight: 600;
}

.closeNotification {
  position: absolute;
  right: 10px;
  top: 7px;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border-radius: 50px;
  z-index: 99;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeNotification img {
  width: 10px;
  height: 10px;
}

.loading_main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Reset Password */

.reset_password_main {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-top: 30px;
}

.reset_password_title {
  text-align: center;
  color: #131313;
  font-size: 25px;
  font-weight: 700;
}

.reset_password_button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}